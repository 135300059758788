/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.cursorPointer{
    cursor: pointer;

}

.full-width{
    width: 100%;
}

.text-justify #swal2-content {
  text-align: justify !important;
}

.full-height{
    height: 100%;
}
.font-weight-normal-bold{
    font-weight:500 !important;
}

body{
    font-family: 'Muli';
}
@font-face {
  font-family: 'Muli';
  src: url('assets/fonts/Muli/Muli-VariableFont_wght.ttf') format('truetype');
  font-weight: 1 999;
}

@font-face {
  font-family: 'Muli Italic';
  src: url('assets/fonts/Muli/Muli-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 1 999;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat/Montserrat-VF.ttf') format('truetype');
  font-weight: 1 999;
}

h5 {
  font-weight: 700;
  font-size: 21px;
}

h6 {
  font-weight: 500;
  font-size: 20px;
  text-transform: none;
}


.sidebar, .off-canvas-sidebar{
    top: 85px;
}

.perfect-scrollbar-on .sidebar, .perfect-scrollbar-on .main-panel {
    max-height: calc(100% - 85px);
}

.font-size-20{
    font-size: 20px;
}

.font-size-15 {
  font-size: 15px;
}

.header-type-1 {
  color: #444444;
  font-weight: 650;
  font-size: 22px;
  line-height: 35px;
}
.header-type-2 {
  color: #444444;
  font-weight: 400;
  font-size: 22px;
  line-height: 35px;
}
.header-type-3 {
  color: #444444;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}

.card {
  margin-bottom: 30px;
}

#timeoutmodal.modal{
    z-index: 11111;
}
.modal-backdrop{
  z-index: 11110;
}

.mat-tooltip {
  font-size: 12px !important;
}
